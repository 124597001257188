<script>
import NotificationServiceApi from "@/service/api/notifications";
export default {
  data() {
    return {
      msg: {
        title: null,
        message: null,
        id: null,
      },
      sending: false,
    };
  },
  methods: {
    submitHandler(data) {
      this.brodCastMessage(data);
    },
    brodCastMessage(data) {
      this.sending = true;
      NotificationServiceApi.userPushNotification(data)
        .then(() => {
          this.actionMessage("Send successfully");
          this.msg.title = null;
          this.msg.message = null;
          this.$formulate.reset("msg-user");
        })
        .catch(() => {
          this.actionMessage("Broadcast failed", "danger");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    actionMessage(message, variant = "success") {
      this.$bvToast.toast(message, {
        title: "Notification Broadcasting",
        variant: variant,
        solid: true,
      });
    },
  },
  mounted() {
    this.msg.id = this.$route.params.id;
    console.log("data is", this.msg);
  },
};
</script>

<template>
  <b-card class="pushNotification">
    <FormulateForm name="msg-user" v-model="msg" @submit="submitHandler">
      <FormulateInput
        type="text"
        name="title"
        label="Title"
        placeholder="Title here!"
        validation="required"
      />
      <FormulateInput
        type="textarea"
        name="message"
        label="Message"
        placeholder="Message here!"
        validation="required|max:50,length"
      />
      <FormulateInput type="submit" class="float-right">
        <div class="btn-text" v-if="sending">
          Sending
          <b-spinner
            type="grow"
            small
            class="ml-1"
            variant="danger"
            role="status"
          ></b-spinner>
        </div>
        <div class="btn-text" v-else>
          Send
        </div>
      </FormulateInput>
    </FormulateForm>
  </b-card>
</template>

<style>
.pushNotification {
  min-height: 500px;
  padding: 50px;
}
.pushNotification textarea {
  height: 250px;
}

.pushNotification .formulate-input[data-classification="button"] button {
  text-align: center;
  width: 100px;
}
.pushNotification .btn-text {
  width: 100%;
}
</style>
